export class TeeColor {
  constructor(h = 0, s = 0, l = 0) {
    this.h = h;
    this.s = s;
    this.l = l;
  }

  toString() {
    return `tee(${this.h}, ${this.s}, ${this.l})`;
  }
}

export class HslColor {
  constructor(h = 0, s = 0, l = 0) {
    this.h = h;
    this.s = s;
    this.l = l;
  }

  static fromTeeColor(teeColor) {
    return new HslColor(
      teeColor.h / 255,
      teeColor.s / 255,
      0.5 + 0.5 * teeColor.l / 255
    );
  }

  toString() {
    return `hsl(${this.h * 360 | 0}, ${this.s * 100 | 0}, ${this.l * 100 | 0})`;
  }
}

export class RgbColor {
  constructor(r = 0, g = 0, b = 0) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  static fromHslColor(hslColor) {
    const temp = new RgbColor();
    
    const h1 = hslColor.h * 6;
    const c = (1 - Math.abs(2 * hslColor.l - 1)) * hslColor.s;
    const x = c * (1 - Math.abs(h1 % 2 - 1));

    switch (h1 | 0)
    {
      case 0:
        temp.r = c;
        temp.g = x;
        break;
      case 1:
        temp.r = x;
        temp.b = c;
        break;
      case 2:
        temp.g = c;
        temp.b = x;
        break;
      case 3:
        temp.g = x;
        temp.b = c;
        break;
      case 4:
        temp.r = x;
        temp.b = c;
        break;
      case 5:
      case 6:
        temp.r = c;
        temp.b = x;
        break;
    }

    const m = hslColor.l - (c / 2);

    return new RgbColor(
      temp.r + m,
      temp.g + m,
      temp.b + m
    );
  }

  static fromTeeColor(teeColor) {
    return this.fromHslColor(HslColor.fromTeeColor(teeColor));
  }

  toString() {
    return `rgb(${this.r * 255 | 0}, ${this.g * 255 | 0}, ${this.b * 255 | 0})`;
  }
}
