<template>
  <b-card no-body id="teeView" class="d-flex align-items-center justify-content-center">
    <canvas id="teePreview" ref="tee-preview"></canvas>
  </b-card>
</template>

<script>
import tee from '../tee'

export default {
  name: 'TeeView',
  props: ['options'],

  data() {
    return {
      size: 96
    }
  },

  watch: {
    options(newVal, oldVal) {
      this.render(newVal);
    }
  },

  methods: {
    render(options) {
      this.clear();

      if (!options || !options.skin)
        return;

      const [canvas, ctx] = this.getPreview();
      const render = tee.render(options);
      canvas.width = render.width;
      canvas.height = render.height;
      ctx.drawImage(render, 0, 0);
    },

    clear() {
      const [canvas, ctx] = this.getPreview();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },

    getPreview() {
      const canvas = this.$refs['tee-preview'];
      const ctx = canvas.getContext('2d');
      return [canvas, ctx];
    }
  }
}
</script>

<style scoped lang="scss">
#teeView {
  height: 256px;
}

#teePreview {
  height: 192px;
}
</style>
