<template>
  <div>
    <b-form-file
      v-model="skinFile"
      @input="getSkin($event)"
      :state="this.isValidSkin"
      placeholder="Choose a skin..."
      drop-placeholder="Drop skin here..."
      accept=".png"
    ></b-form-file>
    <b-form-invalid-feedback :state="isValidSkin">
      The image must have 2:1 ratio and minimum size of 256x128px.
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import tee from "../tee";

export default {
  name: "InputSkin",

  data() {
    return {
      skinFile: null,
      isValidSkin: null,
    };
  },

  methods: {
    getSkin(file) {
      if (!file) {
        this.isValidSkin = null;
        this.$emit("input", null);
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          if (!tee.isValidSkin(img)) {
            this.isValidSkin = false;
            this.$emit("input", null);
            return;
          }

          this.isValidSkin = true;
          this.$emit('input', img);
        };

        img.src = reader.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
