<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group 
          label="Eyes:" 
          label-for="eyesSelect"
        >
          <b-form-select
            id="eyesSelect"
            v-model="eyes"
            @change="buildOptions()"
            :options="eyesOptions"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group 
          label="Face:" 
          label-for="faceSelect"
        >
          <b-form-select
            id="faceSelect"
            v-model="face"
            @change="buildOptions()"
            :options="faceOptions"
          >
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Extras:">
          <b-form-checkbox
            v-model="noFace"
            @change="buildOptions()"
          >
            No Face
          </b-form-checkbox>

          <b-form-checkbox
            v-model="noFeet"
            @change="buildOptions()"
          >
            No Feet
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import tee from "../tee";

export default {
  name: "TeeOptionsTab",

  data() {
    return {
      eyes: tee.EYES.NORMAL,
      eyesOptions: [
        { value: tee.EYES.NORMAL, text: 'Normal' },
        { value: tee.EYES.ANGRY, text: 'Angry' },
        { value: tee.EYES.HURT, text: 'Hurt' },
        { value: tee.EYES.HAPPY, text: 'Happy' },
        { value: tee.EYES.NINJA, text: 'Ninja' },
        { value: tee.EYES.SURPRISED, text: 'Surprised' },
        { value: tee.EYES.SAD, text: 'Sad' }
      ],

      face: tee.FACES.RIGHT,
      faceOptions: [
        { value: tee.FACES.RIGHT, text: "Right" },
        { value: tee.FACES.LEFT, text: "Left" },
        { value: tee.FACES.FRONT, text: "Front" }
      ],

      noFace: false,
      noFeet: false
    };
  },

  methods: {
    buildOptions() {
      this.$emit("change", {
        eyes: this.eyes,
        face: this.face,
        noFace: this.noFace,
        noFeet: this.noFeet
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
