<template>
  <b-container> 
    <b-row>
        <b-col>
          <p>Get skins from <a href="https://ddnet.tw/skins/" target="_blank">ddnet.tw</a> or <a href="https://skins.tw/" target="_blank">skins.tw</a>.</p>

          <div>
            <input-skin @input="setSkin($event)"></input-skin>
          </div>
        </b-col>

        <b-col class="text-right">
          <img
            v-if="skin"
            :src="skin.src"
            ref="skin-preview"
            id="skinPreview"
          >
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import InputSkin from './InputSkin.vue'

export default {
  name: 'TeeSkinTab',
  components: {
    InputSkin
  },

  data() {
    return {
      skin: null
    }
  },

  methods: {
    setSkin(skin) {
      this.skin = skin;
      this.$emit('change', skin);
    }
  }
}
</script>

<style scoped lang="scss">
#skinPreview {
  max-height: 128px;
}
</style>
