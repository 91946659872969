<template>
  <b-container>
    <b-row id="customColorsRow">
      <b-col>
        <b-form-checkbox
          v-model="useCustomColors"
          @change="buildOptions()"
        >
          Custom Colors
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group 
          label="Body:"
        >
          <input-tee-color @input="bodyColor = $event; buildOptions()"></input-tee-color>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group 
          label="Feet:"
        >
          <input-tee-color @input="feetColor = $event; buildOptions()"></input-tee-color>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { TeeColor } from '@/color';
import InputTeeColor from './InputTeeColor.vue'

export default {
  name: "TeeColorsTab",
  components: {
    InputTeeColor
  },

  data() {
    return {
        useCustomColors: false,
        bodyColor: new TeeColor(),
        feetColor: new TeeColor()
    };
  },

  methods: {
    buildOptions() {
      this.$emit("change", {
        useCustomColors: this.useCustomColors,
        bodyColor: this.bodyColor,
        feetColor: this.feetColor
      });
    },
  },
};
</script>

<style scoped lang="scss">
  #customColorsRow {
    margin-bottom: 4px;
  }
</style>
