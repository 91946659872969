<template>
  <div>
    <b-row>
      <b-col>
        <div 
          id="colorPreview"
          :style="colorPreview"
        >
        </div>
      </b-col>

      <b-col cols="8">
        <b-row>
          <b-col cols="4">
            Hue:
          </b-col>
          <b-col>
            <b-form-input
              v-model="h"
              @input="buildColor()"
              type="number"
              min="0"
              max="255"
            >
            </b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            Sat:
          </b-col>
          <b-col>
            <b-form-input
              v-model="s"
              @input="buildColor()"
              type="number"
              min="0"
              max="255"
            >
            </b-form-input>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            Lht:
          </b-col>
          <b-col>
            <b-form-input
              v-model="l"
              @input="buildColor()"
              type="number"
              min="0"
              max="255"
            >
            </b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { TeeColor, RgbColor } from '@/color'

export default {
  name: "InputTeeColor",

  data() {
    return {
      colorPreview: {},
      h: 127,
      s: 127,
      l: 127
    };
  },

  methods: {
    buildColor() {
      const teeColor = new TeeColor(this.h, this.s, this.l);
      const rgbColor = RgbColor.fromTeeColor(teeColor);

      this.colorPreview.backgroundColor = rgbColor.toString();

      this.$emit("input", teeColor);
    },
  },

  beforeMount() {
    this.buildColor();
  }
};
</script>

<style scoped lang="scss">
  #colorPreview {
    aspect-ratio: 1;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
</style>
