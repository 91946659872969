import CanvasUtils from './canvasutils';
import { RgbColor } from "./color";

export default {
  MIN_CANVAS_SIZE: 64,
  MIN_SKIN_WIDTH: 256,
  MIN_SKIN_HEIGHT: 128,
  TILE: 32,
  PARTS: {
    body: {
      normalSize: [96, 96],
      normalOffset: [0, 0],
      skinScale: 2/3,
      skinOffset: [0, 0]
    }
  },
  EYES: {
    NORMAL: 0,
    ANGRY: 1,
    HURT: 2,
    HAPPY: 3,
    NINJA: 4,
    SURPRISED: 5,
    SAD: 6
  },
  FACES: {
    RIGHT: 0,
    LEFT: 1,
    FRONT: 2
  },

  isValidSkin(img) {
    return (
      img && img.width / img.height == 2 && img.width >= this.MIN_SKIN_WIDTH && img.width >= this.MIN_SKIN_HEIGHT
    );
  },

  render(options) {
    if (!options.skin) return null;

    const skinHeight = options.skin.height;
    const canvasSize = skinHeight * 0.75;

    let bodyCanvas = this.renderBody(options.skin, canvasSize);
    let bodyShadowCanvas = this.renderBodyShadow(options.skin, canvasSize);
    let frontFootCanvas = this.renderFrontFoot(options.skin, canvasSize);
    let frontFootShadowCanvas = this.renderFrontFootShadow(options.skin, canvasSize);
    let backFootCanvas = this.renderBackFoot(options.skin, canvasSize);
    let backFootShadowCanvas = this.renderBackFootShadow(options.skin, canvasSize);
    let eyesCanvas = this.renderEyes(options.skin, canvasSize, options.eyes, options.face);

    if (options.useCustomColors)
    {
      const bodyRgbColor = RgbColor.fromTeeColor(options.bodyColor);
      bodyCanvas = CanvasUtils.tint(bodyCanvas, bodyRgbColor, true);
      bodyShadowCanvas = CanvasUtils.tint(bodyShadowCanvas, bodyRgbColor);
      eyesCanvas = CanvasUtils.tint(eyesCanvas, bodyRgbColor);

      const feetRgbColor = RgbColor.fromTeeColor(options.feetColor);
      frontFootCanvas = CanvasUtils.tint(frontFootCanvas, feetRgbColor);
      frontFootShadowCanvas = CanvasUtils.tint(frontFootShadowCanvas, feetRgbColor);
      backFootCanvas = CanvasUtils.tint(backFootCanvas, feetRgbColor);
      backFootShadowCanvas = CanvasUtils.tint(backFootShadowCanvas, feetRgbColor);
    }

    return CanvasUtils.merge(
      bodyShadowCanvas,
      options.noFeet ? null : backFootShadowCanvas,
      options.noFeet ? null : frontFootShadowCanvas,
      options.noFeet ? null : backFootCanvas,
      bodyCanvas,
      options.noFeet ? null : frontFootCanvas,
      options.noFace ? null : eyesCanvas
    );
  },

  renderBody(skin, canvasSize) {
    return this.renderSkinPart(
      skin,
      canvasSize,
      this.getBody,
      this.TILE * 3,
      0, 0,
      2/3
    );
  },

  getBody(skin) {
    return this.getSkinPart(
      skin,
      this.TILE * 3, this.TILE * 3,
      0, 0
    );
  },

  renderBodyShadow(skin, canvasSize) {
    return this.renderSkinPart(
      skin,
      canvasSize,
      this.getBodyShadow,
      this.TILE * 3,
      0, 0,
      2/3
    );
  },

  getBodyShadow(skin) {
    return this.getSkinPart(
      skin,
      this.TILE * 3, this.TILE * 3,
      this.TILE * 3, 0
    );
  },

  renderFrontFoot(skin, canvasSize) {
    return this.renderSkinPart(
      skin,
      canvasSize,
      this.getFoot,
      this.TILE * 2,
      6.875, 30
    );
  },

  renderFrontFootShadow(skin, canvasSize) {
    return this.renderSkinPart(
      skin,
      canvasSize,
      this.getFootShadow,
      this.TILE * 2,
      6.875, 30
    );
  },

  renderBackFoot(skin, canvasSize) {
    return this.renderSkinPart(
      skin,
      canvasSize,
      this.getFoot,
      this.TILE * 2,
      -6.875, 30
    );
  },

  renderBackFootShadow(skin, canvasSize) {
    return this.renderSkinPart(
      skin,
      canvasSize,
      this.getFootShadow,
      this.TILE * 2,
      -6.875, 30
    );
  },

  getFoot(skin) {
    return this.getSkinPart(
      skin,
      this.TILE * 2, this.TILE,
      this.TILE * 6, this.TILE
    );
  },

  getFootShadow(skin) {
    return this.getSkinPart(
      skin,
      this.TILE * 2, this.TILE,
      this.TILE * 6, this.TILE * 2
    );
  },

  renderEyes(skin, canvasSize, eye = 0, face = 0) {
    const [canvas, ctx] = CanvasUtils.create(canvasSize);

    let leftOffsetX = 23.0625;
    let rightOffsetX = 31.375;
    const offsetY = 15.9625;
    const scale = 0.8;

    if (face == this.FACES.FRONT)
    {
      leftOffsetX = 14.96625;
      rightOffsetX = 23.27875;
    }

    const leftEyeCanvas = this.renderEye(
      skin,
      canvasSize,
      this.TILE,
      leftOffsetX, offsetY,
      scale,
      eye == this.EYES.SAD ? this.EYES.ANGRY : eye,
      eye == this.EYES.SAD
    );

    const rightEyeCanvas = this.renderEye(
      skin,
      canvasSize,
      this.TILE,
      rightOffsetX, offsetY,
      scale,
      eye == this.EYES.SAD ? this.EYES.ANGRY : eye,
      eye != this.EYES.SAD
    );

    ctx.drawImage(
      leftEyeCanvas,
      0, 0
    );
    ctx.drawImage(
      rightEyeCanvas,
      0, 0
    );

    if (face == this.FACES.LEFT)
      return CanvasUtils.flip(canvas);

    return canvas;
  },

  getEye(skin, eye = 0) {
    return this.getSkinPart(
      skin,
      this.TILE, this.TILE,
      this.TILE * 2 + this.TILE * eye, this.TILE * 3
    );
  },

  renderSkinPart(skin, canvasSize, getSkinPartFn, normalSize, normalOffsetX, normalOffsetY, scale = 1) {
    const [canvas, ctx] = CanvasUtils.create(canvasSize);

    const partCanvas = getSkinPartFn.call(this, skin);

    const offsetX = this.calculateRelativeSize(canvasSize, normalOffsetX, this.MIN_CANVAS_SIZE);
    const offsetY = this.calculateRelativeSize(canvasSize, normalOffsetY, this.MIN_CANVAS_SIZE);

    const normalize = 1 / (partCanvas.width / normalSize);
    const width = this.calculateRelativeSize(canvasSize, partCanvas.width * normalize * scale, this.MIN_CANVAS_SIZE);
    const height = this.calculateRelativeSize(canvasSize, partCanvas.height * normalize * scale, this.MIN_CANVAS_SIZE);

    ctx.drawImage(
      partCanvas,
      0, 0,
      partCanvas.width, partCanvas.height,
      offsetX, offsetY,
      width, height
    );

    return canvas;
  },

  renderEye(skin, canvasSize, normalSize, normalOffsetX, normalOffsetY, scale = 1, eye = 0, flip = false) {
    const [canvas, ctx] = CanvasUtils.create(canvasSize);

    let partCanvas = this.getEye(skin, eye);
    if (flip)
      partCanvas = CanvasUtils.flip(partCanvas);

    const offsetX = this.calculateRelativeSize(canvasSize, normalOffsetX, this.MIN_CANVAS_SIZE);
    const offsetY = this.calculateRelativeSize(canvasSize, normalOffsetY, this.MIN_CANVAS_SIZE);

    const normalize = 1 / (partCanvas.width / normalSize);
    const width = this.calculateRelativeSize(canvasSize, partCanvas.width * normalize * scale, this.MIN_CANVAS_SIZE);
    const height = this.calculateRelativeSize(canvasSize, partCanvas.height * normalize * scale, this.MIN_CANVAS_SIZE);

    ctx.drawImage(
      partCanvas,
      0, 0,
      partCanvas.width, partCanvas.height,
      offsetX, offsetY,
      width, height
    );

    return canvas;
  },

  getSkinPart(skin, normalWidth, normalHeight, normalOffsetX, normalOffsetY) {
    const [canvas, ctx] = CanvasUtils.create(
      this.calculateRelativeSize(skin.width, normalWidth, this.MIN_SKIN_WIDTH),
      this.calculateRelativeSize(skin.height, normalHeight, this.MIN_SKIN_HEIGHT)
    );

    const offsetX = this.calculateRelativeSize(skin.width, normalOffsetX, this.MIN_SKIN_WIDTH);
    const offsetY = this.calculateRelativeSize(skin.height, normalOffsetY, this.MIN_SKIN_HEIGHT);

    ctx.drawImage(
      skin,
      offsetX, offsetY,
      canvas.width, canvas.height,
      0, 0,
      canvas.width, canvas.height
    );

    return canvas;
  },

  calculateRelativeSize(skinSize, normalSize, minSize) {
    return (skinSize / minSize) * normalSize;
  }
};
