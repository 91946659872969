export default {
  create(width, height = undefined) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height || width;

    const ctx = canvas.getContext("2d");

    return [canvas, ctx];
  },

  merge(...canvases) {
    if (canvases.length == 0) return null;

    let mergedCanvas, ctx;

    for (const canvas of canvases) {
      if (!canvas) continue;

      if (!mergedCanvas)
        [mergedCanvas, ctx] = this.create(canvas.width);

      ctx.drawImage(canvas, 0, 0);
    }

    return mergedCanvas;
  },

  flip(canvas) {
    const [flippedCanvas, ctx] = this.create(canvas.width);

    ctx.save();
    ctx.translate(canvas.width, 0);
    ctx.scale(-1, 1);
    ctx.drawImage(canvas, 0, 0);
    ctx.restore();

    return flippedCanvas;
  },

  tint(canvas, rgbColor, weightedGrayscale = false) {
    const [tintedCanvas, ctx] = this.create(canvas.width, canvas.height);

    ctx.drawImage(canvas, 0 ,0);
    
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imgData.data;

    for (let i = 0; i < pixels.length; i += 4) {
      const gray = parseInt((pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);
      pixels[i] = gray;
      pixels[i + 1] = gray;
      pixels[i + 2] = gray;
    }

    if (weightedGrayscale) {
        let f = Array(256).fill(0);
        let orgWeight = 0;
        let newWeight = 192;
    
        for (let i = 0; i < pixels.length; i+= 4) {
          if (pixels[i + 3] > 128)
            f[pixels[i]]++;
        }
    
        for (let i = 1; i < 256; i++) {
          if (f[orgWeight] < f[i])
            orgWeight = i;
        }
    
        let invOrgWeight = 255 - orgWeight;
        let invNewWeight = 255 - newWeight;
    
        for (let i = 0; i < pixels.length; i += 4) {
          let v = pixels[i];
    
          if (v <= orgWeight && orgWeight == 0)
            v = 0;
          else if (v <= orgWeight)
            v = v / orgWeight * newWeight;
          else if (invOrgWeight == 0)
            v = newWeight;
          else
            v = ((v - orgWeight) / invOrgWeight) * invNewWeight + newWeight;
    
            pixels[i] = v;
            pixels[i + 1] = v;
            pixels[i + 2] = v;
        }
    }

    for (let i = 0; i < pixels.length; i += 4) {
      pixels[i] = pixels[i] * rgbColor.r;
      pixels[i + 1] = pixels[i + 1] * rgbColor.g;
      pixels[i + 2] = pixels[i + 2] * rgbColor.b;
    }

    ctx.putImageData(imgData, 0, 0);

    return tintedCanvas;
  }
};
