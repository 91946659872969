<template>
  <div id="app">
    <b-container class="main-container">
      <b-row>
        <b-col>
          <h1>TeeView</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <tee-view :options="options"></tee-view>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <tee-options @change="options = $event"></tee-options>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TeeView from './components/TeeView.vue'
import TeeOptions from './components/TeeOptions.vue'

export default {
  name: 'App',
  components: {
    TeeView,
    TeeOptions
  },

  data() {
    return {
      options: null
    }
  }
}
</script>

<style lang="scss">
* {
  user-select: none;
}

#app {
  height: 100vh;
  display: flex;
  align-items: center;
}

.main-container > * {
  margin: 8px 0;
}
</style>
