<template>
  <b-card id="teeOptions" no-body>
    <b-tabs card>
      <b-tab title="Skin">
        <tee-skin-tab @change="skin = $event; buildOptions();"></tee-skin-tab>
      </b-tab>

      <b-tab title="Colors" :disabled="!Boolean(skin)">
        <tee-colors-tab @change="colorOptions = $event; buildOptions();"></tee-colors-tab>
      </b-tab>

      <b-tab title="Options" :disabled="!Boolean(skin)">
        <tee-options-tab @change="skinOptions = $event; buildOptions()"></tee-options-tab>
      </b-tab>

      <!-- <b-tab title="Export" :disabled="!Boolean(skin)">

      </b-tab> -->
    </b-tabs>
  </b-card>
</template>

<script>
import TeeSkinTab from './TeeSkinTab.vue'
import TeeColorsTab from './TeeColorsTab.vue'
import TeeOptionsTab from './TeeOptionsTab.vue'

export default {
  name: 'TeeOptions',
  components: {
    TeeSkinTab,
    TeeColorsTab,
    TeeOptionsTab
  },

  data() {
    return {
      skin: null,
      colorOptions: {},
      skinOptions: {}
    }
  },

  methods: {
    buildOptions() {
      this.$emit('change', {
        skin: this.skin,
        useCustomColors: this.colorOptions.useCustomColors,
        bodyColor: this.colorOptions.bodyColor,
        feetColor: this.colorOptions.feetColor,
        eyes: this.skinOptions.eyes,
        face: this.skinOptions.face,
        noFace: this.skinOptions.noFace,
        noFeet: this.skinOptions.noFeet
      });
    }
  }
}
</script>

<style scoped lang="scss">
#teeOptions {
  height: 256px;
}
</style>
